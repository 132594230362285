.domaincontainer {
	padding: 3em;
	width: 25vw;
	height: 70vh;
	display: flex;
	flex-direction: column;
	/* border: #a0ff28 2px solid; */
	border-radius: 10px;
	backdrop-filter: blur(10px);
	background-color: #00000099;
	justify-content: space-between;
	transition: box-shadow 0.3s;
}

.domaincontainer:hover {
	box-shadow: 10px 10px #211e1e1a;
}

.domainimage {
	display: flex;
	justify-content: center;
	align-items: center;
	padding-top: 3em;
	position: relative;
}

.domainheading {
	width: 95%;
	height: 10%;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	color: white;
	font-size: 4rem;
	font-family: 'Neue Machina';
	font-weight: bold;
}

.domainimage > img {
	border-radius: 10px;
	max-width: 60% !important;
}

.domaintitle {
	text-align: center;
	font-family: 'Roboto';
	padding: 0.5em;
	font-size: 3em;
	color: #a0ff28;
	text-underline-offset: 10px;
	text-decoration: underline #1f3d0c 3px;
}

.domaincontent {
	padding: 0.3em;
	font-family: 'Roboto';
	font-weight: 100;
	color: #d7dae5;
	line-height: 1.2em;
	font-size: 1.7em;
	text-align: center;
	overflow: auto;
}

.domains {
	display: flex;
	width: 100%;
	height: 80%;
	align-items: center;
	justify-content: space-evenly;
}

.domainspage {
	height: 100vh;
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	scroll-snap-align: center;
	flex-direction: column;
	position: relative;
}

@media (width<=1500px) {
	.domaincontent {
		font-size: 1.2em;
	}
	.domainspage {
		padding: 0 2em;
		display: flex;
		justify-content: space-evenly;
		align-items: center;
	}
	.domaintitle {
		font-size: 2.5em;
		margin-bottom: 1em;
	}
}

@media (width<=1500px) and (width>1100px) {
	.domaincontainer {
		position: relative;
		padding: 3em;
		width: 23rem;
		height: 35rem;
	}

	.domainimage > img {
		margin-top: -2rem;
		height: 8rem;
		width: auto;
	}
}

@media (width<=1100px) {
	.domaincontent {
		font-size: 1.2em;
	}
}

@media (width<=1100px) and (width>600px) {
	.domainspage {
		flex-direction: column;
		height: auto;
		padding: 0 4em;
		gap: 5rem;
		margin-bottom: 6rem;
	}

	.domains {
		gap: 3vw;
	}

	.domaintitle {
		font-size: 2em;
	}

	.domainimage > img {
		margin-top: -4rem;
		height: 10rem;
		width: auto;
	}

	.domaincontainer {
		position: relative;
		padding: 3em;
		width: 30rem;
		height: 35rem;
		display: flex;
		flex-direction: column;
		background-color: #000003;
		justify-content: space-between;
		text-align: center;
	}
}

@media (width<=600px) {
	.domainheading {
		font-size: 2rem;
		justify-content: center;
	}

	.domains {
		flex-direction: column;
		gap: 10vh;
	}

	.domainspage {
		height: auto;
		padding: 0 3em;
		gap: 5rem;
		margin-bottom: 6rem;
		flex-direction: column;
		scroll-snap-align: none;
	}

	.domainimage > img {
		margin-top: -4rem;
		height: 10rem;
		width: auto;
	}

	.domaincontainer {
		padding: 1.3em;
		width: 80vw;
		height: 35rem;
		/* max-height: 35rem; */
		display: flex;
		flex-direction: column;
		justify-content: space-evenly;
		text-align: center;
	}

	.domaincontent {
		font-size: 1.1em;
	}
}
