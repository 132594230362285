.cont {
	background: radial-gradient(#000, #111);
	height: 100% !important;
	margin-left: 0 !important;
	margin-right: 0 !important;
	position: relative;
	overflow: hidden;
	display: flex;
	justify-content: center;
	align-items: center;
}

.static {
	width: 100%;
	height: 100%;
	position: relative;
	margin: 0;
	padding: 0;
	top: -100px;
	opacity: 0.05;
	z-index: 230;
	user-select: none;
}

.returnBtn {
	position: absolute;
	bottom: 60px;
	width: 200px;
	z-index: 250;
}

.error {
	text-align: center !important;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
		'Droid Sans', 'Helvetica Neue', sans-serif;
	font-size: 15rem;
	font-style: bold;
	text-align: center;
	color: white;
	width: auto;
	height: 3rem;
	line-height: 60px;
	margin: auto;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	animation: noise 2s linear infinite;
}

.error:after {
	content: '404';
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
		'Droid Sans', 'Helvetica Neue', sans-serif;
	font-size: 16rem;
	text-align: center;
	width: auto;
	height: 3rem;
	line-height: 60px;
	margin: auto;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 20px;
	right: 0;
	opacity: 0;
	color: blue;
	animation: noise-1 0.2s linear infinite;
}

.error:before {
	content: '404';
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
		'Droid Sans', 'Helvetica Neue', sans-serif;
	font-size: 16rem;
	text-align: center;
	width: auto;
	height: 3rem;
	line-height: 60px;
	margin: auto;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 20px;
	right: 0;
	opacity: 0;
	color: rgb(38, 255, 0);
	animation: noise-2 0.2s linear infinite;
}

.info {
	text-align: center;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
		'Droid Sans', 'Helvetica Neue', sans-serif;
	font-style: italic;
	color: white;
	text-align: center;
	width: auto;
	height: 6rem;
	line-height: 60px;
	margin: auto;
	position: absolute;
	bottom: 80px;
	left: 0;
	right: 0;
	animation: noise-3 1s linear infinite;
}

@media screen and (max-width: 768px) {
	.cont {
		background: radial-gradient(#000, #111);
		height: 100% !important;
		width: 100% !important;
		margin-left: 0 !important;
		margin-right: 0 !important;
		position: relative;
		overflow: hidden;
	}

	.error {
		text-align: center !important;
		font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
			'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
		font-size: 10rem;
		font-style: bold;
		text-align: center;
		color: white;
		width: 5rem;
		height: 3rem;
		line-height: 60px;
		margin: auto;
		position: absolute;
		top: 0;
		bottom: 0;
		left: -15rem;
		right: 0;
		animation: noise 2s linear infinite;
	}

	.error:after {
		content: '404';
		font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
			'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
		font-size: 10rem;
		font-style: italic;
		text-align: center;
		width: 5rem;
		height: 3rem;
		line-height: 60px;
		margin: auto;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		opacity: 0;
		color: blue;
		animation: noise-1 0.2s linear infinite;
	}

	.error:before {
		content: '404';
		font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
			'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
		font-size: 10rem;
		font-style: italic;
		text-align: center;
		width: 5rem;
		height: 3rem;
		line-height: 60px;
		margin: auto;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		opacity: 0;
		color: rgb(38, 255, 0);
		animation: noise-2 0.2s linear infinite;
	}
}

@keyframes noise-1 {
	0%,
	20%,
	40%,
	60%,
	70%,
	90% {
		opacity: 0;
	}
	10% {
		opacity: 0.1;
	}
	50% {
		opacity: 0.5;
		left: -6px;
	}
	80% {
		opacity: 0.3;
	}
	100% {
		opacity: 0.6;
		left: 2px;
	}
}

@keyframes noise-2 {
	0%,
	20%,
	40%,
	60%,
	70%,
	90% {
		opacity: 0;
	}
	10% {
		opacity: 0.1;
	}
	50% {
		opacity: 0.5;
		left: 6px;
		top: 10px;
	}
	80% {
		opacity: 0.3;
	}
	100% {
		opacity: 0.6;
		left: -2px;
		top: -5px;
	}
}

@keyframes noise {
	0%,
	3%,
	5%,
	42%,
	44%,
	100% {
		opacity: 1;
		transform: scaleY(1);
	}
	4.3% {
		opacity: 1;
		transform: scaleY(1.7);
	}
	43% {
		opacity: 1;
		transform: scaleX(1.5);
	}
}

@keyframes noise-3 {
	0%,
	3%,
	5%,
	42%,
	44%,
	100% {
		opacity: 1;
		transform: scaleY(1);
	}
	4.3% {
		opacity: 1;
		transform: scaleY(4);
	}
	43% {
		opacity: 1;
		transform: scaleX(10) rotate(60deg);
	}
}
