.accordion {
	background-color: rgb(40, 40, 40);
	color: white;
	cursor: pointer;
	padding: 18px;
	width: 100%;
	text-align: left;
	border: none;
	outline: none;
	transition: 0.4s;
}

.active,
.accordion:hover {
	background-color: #28a315;
}

.panel {
	padding: 0 18px;
	background-color: rgb(50, 50, 50);
	max-height: 0px;
	overflow: hidden;
	transition: max-height 0.2s ease-out;
}

.accordion:after {
	content: '+';
	font-size: 13px;
	color: white;
	float: right;
	margin-left: 5px;
}

.active:after {
	color: white;
	content: '-';
}
