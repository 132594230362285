.landingpage {
	height: 100vh;
	display: flex;
	flex-direction: column;
	scroll-snap-align: center;
}

.page1 {
	flex: 1 1 auto;
	display: flex;
	align-items: center;
	justify-content: center;
}

.buttonNav {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	padding: 1.5rem 3rem;
	margin-bottom: 0rem;
	height: fit-content;
}

.navButton {
	font-size: 1.3rem !important;
	margin-left: 1rem !important;
	z-index: 10000;
}

.landinglogoAndText {
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: 1fr;
	height: 100%;
	width: 100%;
}

.logo {
	grid-row: 1 / -1;
	grid-column: 1 / -1;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 80vh;
	width: 100vw;
	animation: logotransform 5s infinite;
}

.logo > img {
	width: 80%;
	height: 80%;
}

.landingtext {
	grid-row: 1/-1;
	grid-column: 1/-1;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-evenly;
	color: #ffffff;
	font-size: 6em;
	z-index: 4;
	letter-spacing: 0.2rem;
	font-family: 'Neue Machina';
	font-weight: bold;
}

@media (width<=1000px) {
	.landingtext {
		font-size: 5em;
	}
}

@media (width>=360px) and (width<=500px) {
	.landingtext {
		position: relative;
		bottom: 3%;
	}
}

@media (width<=500px) {
	.logo > img {
		width: 60%;
		height: 60%;
	}

	.landingtext {
		font-size: 2em !important;
	}
}

@media (width<=800px) {
	.landingtext {
		font-size: 3em;
	}
	.landingpage {
		scroll-snap-align: none;
	}
}
