.sidebar {
	width: 100px !important;
	top: 62px !important;
}

.allocationConatiner {
	position: relative;
	top: 72px;
	left: 110px;
	height: calc(100% - 100px);
	width: calc(100% - 120px);
	background: black;
	border-radius: 8px;
	margin: none;
	/* overflow-y: scroll; */
}

.a {
	padding: 10px 8px !important;
}

.h3 {
	padding-top: 40px;
	color: green;
	text-align: center;
}
.green {
	color: green;
}
.allocationTab {
	margin-top: 15px !important;
	padding: 0px 0px 5px 10px !important;
	height: calc(100% - 70px);
	overflow-y: scroll;
	overflow-x: hidden;
}

.thead {
	background: rgb(65, 64, 64) !important;
	/* padding: 10px 5px !important; */
}
.active {
	background: green !important;
}

@media only screen and (max-width: 700px) {
	.mobileth {
		padding: 0px !important;
	}

	.mobiletd {
		padding: 0px !important;
		background: rgb(29, 29, 29);
	}
}
