.loginContainer {
	border: solid 0.5px rgba(0, 255, 0, 0.69);
	border-radius: 5px;
	padding: 4rem 3.5rem 2.5rem;
	position: relative;
	/* height: 70vh; */
	/* min-height: 50vh; */
	max-height: 55vh;
	/* margin-top: 3rem; */
	background: rgba(4, 4, 4, 0.9);
	width: 400px !important;
	scrollbar-width: none;
	/* backdrop-filter: opacity(0.2); */
	overflow-y: scroll;
	color: white;
}

@supports (backdrop-filter: blur(10px)) {
	.registerContainer {
		background: rgba(4, 4, 4, 0.3) !important;
		backdrop-filter: blur(10px);
	}
}

.spinningImage {
	position: absolute;
	top: 35%;
	left: 50%;
	width: 120px;
	height: 120px;
	margin: -60px 0 0 -60px;
	-webkit-animation: spin 01s ease-in-out infinite;
	-moz-animation: spin 01s ease-in-out infinite;
	animation: spin 1s ease-in-out infinite;
}
@-moz-keyframes spin {
	100% {
		-moz-transform: rotateY(360deg);
	}
}
@-webkit-keyframes spin {
	100% {
		-webkit-transform: rotateY(360deg);
	}
}
@keyframes spin {
	100% {
		-webkit-transform: rotateY(360deg);
		transform: rotateY(360deg);
	}
}

.contentBox {
	position: relative;
	text-align: center;
}

.contentBox p {
	text-align: center;
	margin-bottom: 10px;
}

.link {
	text-decoration: underline;
	color: #0f0;
	margin: 25px 5px;
	cursor: pointer;
}

.moreStuff {
	position: absolute;
	bottom: 2rem;
	width: 80%;
	margin: auto;
}

.loginForm {
	margin: 2rem 0.5rem;
	height: fit-content;
}

.loginForm > * {
	font-size: 1.2rem;
	padding-bottom: 1rem;
	color: #f1f1f1;
}

.loginForm label {
	font-size: 1.2rem !important;
	margin-bottom: 1rem !important;
	color: #f1f1f1 !important;
}

.LoginForm input::-webkit-outer-spin-button,
.LoginForm input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
	-moz-appearance: textfield;
}
.sexyAnimation {
	position: absolute !important;
	top: 5%;
	right: 5%;
	/* transform: translate(-15px, -18px); */
	/* animation: light 10s infinite; */
	/* -webkit-filter: drop-shadow(0 0 2px #0f0);
  filter: drop-shadow(0 0 2px #0f0); */
}

@media only screen and (max-width: 789px) {
	.loginContainer {
		padding: 4rem 1.5rem 2.5rem;
		max-height: 85vh;
	}

	.loginContainer h1 {
		font-size: 2rem !important;
		padding-bottom: 1rem !important;
	}

	.loginForm {
		margin: 0.5rem;
	}

	.sexyAnimation {
		transform: scale(0.8, 0.8);
		position: absolute;
		top: -5%;
		right: -5%;
		/* transform: translate(-15px, -25px); */
	}
}
