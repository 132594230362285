.img {
	height: 100px;
	width: 100px;
	object-fit: contain;
	animation-name: spinRotation;
	animation-duration: 1s;
	animation-iteration-count: infinite;
}

.center {
	position: absolute;
	top: calc(50% - 50px);
	left: calc(50% - 50px);
}

@keyframes spinRotation {
	0% {
		transform: rotateY(0deg);
	}
	33% {
		transform: rotateY(120deg);
	}
	66% {
		transform: rotateY(240deg);
	}
	100% {
		transform: rotateY(360deg);
	}
}
