.whydeltapage {
	height: 100vh;
	display: flex;
	width: 100vw;
	scroll-snap-align: center;
	position: relative;
	flex-direction: column;
}

.deltacarousel {
	flex-basis: 50%;
	height: 100%;
}

.whydeltacontainer {
	display: flex;
	height: 70%;
	width: 100%;
	align-items: center;
	justify-content: space-evenly;
}

.whydeltacontent {
	flex-basis: 50%;
	height: 80%;
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	align-items: center;
	position: relative;
}

.whydeltaheading {
	width: 95%;
	height: 20%;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	color: white;
	font-size: 4rem;
	font-family: 'Neue Machina';
	font-weight: bold;
}

.content {
	width: 40%;
	height: fit-content;
	line-height: 2rem;
	padding: 2rem 2rem;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #00000099;
	backdrop-filter: blur(5px);
	border-radius: 10px;
	color: white;
	font-family: 'Lato', 'Helvetica Neue', 'Arial', 'Helvetica', 'sans-serif';
	font-size: 1.3rem;
	font-weight: 200;
}

.deltacarousel {
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
}

.deltacarousel > img {
	height: auto;
	width: 80%;
	border-radius: 10px;
}

@media (width<=500px) {
	.whydeltacontainer {
		flex-direction: column-reverse;
	}
	.whydeltapage {
		scroll-snap-align: none;
	}
	.content {
		width: 90%;
		font-size: 1rem;
	}

	.whydeltaheading {
		font-size: 2rem;
		justify-content: center;
		margin-bottom: 1rem;
	}

	.content {
		margin-bottom: 1rem;
	}
}
