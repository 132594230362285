.rootContainer {
	margin: 0;
	max-width: 320px;
	display: flex;
	height: 100%;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	background-color: rgb(20, 20, 20);
	color: white;
	border-radius: 8px;
	overflow: auto;
}

.rootContainer::-webkit-scrollbar {
	width: 0;
}

.userDetails {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.domainIcons {
	margin: 8px !important;
}

.domains {
	margin-bottom: 8px;
}

.identicon {
	margin-top: 24px;
	border: solid #28a315 4px;
	height: 150px;
	width: 150px;
	background: black;
}

.stats {
	margin-top: 24px;
	width: 100%;
}

.statsGroup {
	width: 100% !important;
	margin: 0 !important;
	display: flex !important;
	align-items: center !important;
	justify-content: space-around !important;
}

.statsGroup::after {
	content: none !important;
}

.menu {
	margin-top: auto;
	width: 100%;
}

.menuItem {
	display: flex !important;
	padding: 16px !important;
}

.menuIcon {
	margin: 0 !important;
	margin-right: 16px !important;
}
