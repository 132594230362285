@import url('https://fonts.googleapis.com/css2?family=Chivo+Mono:wght@200&family=Inter:wght@800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Chivo+Mono:wght@200&family=Inter:wght@800&family=Roboto:wght@500&display=swap');
html,
body,
#root {
	width: 100%;
	height: 100%;
}

body {
	background-color: black;
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
		'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	height: 100vh;
}

#root {
	height: 100%;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@font-face {
	font-family: 'Neue Machina';
	src: url('./fonts/Neue\ Machina/NeueMachina-Regular.otf');
	font-weight: 500;
}

@font-face {
	font-family: 'Neue Machina';
	src: url('./fonts/Neue\ Machina/NeueMachina-Ultrabold.otf');
	font-weight: bold;
}

@font-face {
	font-family: 'Neue Machina';
	src: url('./fonts/Neue\ Machina/NeueMachina-Light.otf');
	font-weight: lighter;
}
