.root {
	height: 100%;
	padding: 2rem;
	background: black;
}

.sidebar {
	height: 92vh;
	width: 300px;
	position: fixed;
}

.miniSidebar {
	display: none;
}

.main {
	height: 100%;
	color: white;
	margin-left: 300px;
	padding: 0rem 1rem 0rem 3rem;
}

.menuContainer {
	padding: 24px;
	background: rgb(20, 20, 20);
	height: 100%;
	border-radius: 8px;
	overflow: auto;
}

.pyChallengeImage > img {
	border-radius: 50%;
}

@media only screen and (max-width: 862px) {
	.root {
		padding: 0;
	}

	.miniSidebar {
		padding-top: 1rem;
		display: flex;
		height: 100vh;
		flex-direction: column;
		align-items: center;
		justify-content: flex-start;
		position: fixed;
		width: 4rem;
		background: #282828;
	}

	.sidebar {
		transform: translate(-400px);
		height: 100vh;
		transition: transform 400ms ease;
		z-index: 100000000;
	}

	.main {
		margin-left: 4rem;
		margin-right: 0rem;
		padding: 0;
	}

	.menuContainer {
		padding: 16px;
		background: rgb(20, 20, 20);
		height: 100%;
		border-radius: 8px;
		overflow: auto;
	}
}
