.flexBox {
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 100%;
	height: fit-content;
}

.profileContainer {
	background: #1c1c1c;
	padding: 4rem 2rem 5rem;
	position: relative;
	border-radius: 10px;
	box-shadow: 0 0 0 2px rgba(40, 167, 70, 0.65);
	transition: 0.4s;
	margin: 2.5rem;
}

.profileContainer h1,
.profileSubContainer h1 {
	margin-bottom: 2rem !important;
	color: #f1f1f1 !important;
	font-size: 2.5rem !important;
}

.profileSubContainer {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
}

.profileSubContainer > div {
	width: 50%;
}

.profileSubContainer > div:nth-of-type(2) {
	zoom: 1.2;
}

.styledCards {
	background: transparent !important;
	color: #f1f1f1 !important;
	border: none !important;
	box-shadow: none !important;
}
.styledCards *:not(a) {
	background: transparent !important;
	color: #f1f1f1 !important;
	border: none !important;
}

.styledCards img {
	width: 180px !important;
	margin: auto;
}

.profileImage {
	width: fit-content;
	margin: auto;
}

.profileImage img {
	cursor: pointer;
	transition: 0.4s;
	position: relative;
	border: solid 0rem #28a746 !important;
}

.profileImage img:hover {
	border: solid 0.5rem #28a746 !important;
}

.profileImage::before {
	content: '';
	display: block;
	width: 100%;
	background-color: #28a746;
	margin: auto;
	height: 0px;
	position: absolute;
	bottom: 0px;
	transition: all 0.4s;
	z-index: 100;
	color: rgba(255, 255, 255, 0);
}

.profileImage:hover::before {
	content: attr(data-role);
	color: rgba(255, 255, 255, 1);
	text-transform: uppercase;
	font-weight: 600;
	height: 20px;
}

/*Edit Profile Page*/
.profileForm {
	margin: 2rem 0.5rem;
	color: #f1f1f1 !important;
}
.profileForm > * {
	font-size: 1.1rem;
	padding-bottom: 1rem;
}

.profileForm label {
	font-size: 1.3rem !important;
	margin-bottom: 1rem !important;
	color: #f1f1f1 !important;
}

@media only screen and (max-width: 800px) {
	.profileContainer {
		width: 90% !important;
	}

	.profileSubContainer {
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	.profileSubContainer > div {
		width: 100% !important;
		margin: 3rem 2rem;
	}
}
