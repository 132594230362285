.card {
	background: rgb(50, 50, 50);
	color: white;
	padding: 1.5rem;
	display: flex;
	justify-content: space-evenly;
	width: 100%;
	border-radius: 1rem;
}

.image {
	display: flex;
	flex-direction: column;
}

h3 {
	margin-bottom: 0.5rem !important;
}

.info {
	margin-left: 1.5rem;
	display: flex;
	flex-direction: column;
	align-items: left;
}

.header,
.extra,
.info {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.extra {
	margin-top: auto;
}

.identicon {
	border: solid #28a315 4px;
	max-width: 140px;
	height: 140px;
	background: black;
}

.interests {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	max-width: 200px;
	margin-bottom: 8px;
}

.taskProgress {
	padding: 0.5rem;
	display: flex;
	justify-content: center;
}

@media only screen and (max-width: 530px) {
	.card {
		flex-direction: column;
		align-items: center;
	}

	.info {
		margin-left: 0;
		margin-top: 1rem;
	}

	.header {
		margin-bottom: 1.5rem;
	}
}
