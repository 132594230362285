html {
	scroll-snap-type: y mandatory;
	scroll-behavior: smooth;
}

section {
	height: 100vh;
	display: flex !important;
	justify-content: space-between;
	align-items: center;
	scroll-snap-align: center;
	perspective: 500px;
	margin: 0 3.5vw;
}

.titleDiv {
	font-size: 5rem;
	color: #eaeaea;
	filter: drop-shadow(4px 8px 1rem #000000);
	font-family: 'Neue Machina';
	font-weight: bold;
	position: absolute;
	display: flex;
	width: 92vw;
	justify-content: flex-end;
	top: 5vh;
	align-items: center;
}

.parallaxImgContainer {
	position: absolute;
	left: 12vw;
	z-index: -1;
}

.parallaxImg {
	max-height: 60vh;
	height: 100%;
	width: auto;
	max-width: 45vw;
	border-radius: 15px;
}
.parallaxDiv {
	font-size: 5rem;
	line-height: 5.2rem;
	max-width: 35vw;
	width: max-content;
	color: #eaeaea;
	filter: drop-shadow(4px 8px 1rem #000000);
	font-family: 'Neue Machina';
	font-weight: bold;
}
.parallaxContent {
	justify-self: flex-end;
	font-size: 1.5rem;
	color: #eaeaea;
	margin-right: 2rem;
	max-width: 40vw;
	line-height: 2.25rem;
	background-color: #00000099;
	backdrop-filter: blur(5px);
	padding: 2rem;
	border-radius: 10px;
}

@media screen and (max-width: 500px) {
	section {
		flex-direction: column;
		justify-content: space-between;
		height: 80vh;
		scroll-snap-align: none;
	}
	.parallaxDiv {
		max-width: inherit;
		font-size: 2.6rem;
	}
	.parallaxImgContainer {
		position: inherit;
		top: 40vh;
	}
	.parallaxContent {
		max-width: 90vw;
		margin-right: 0;
	}
	.parallaxImg {
		max-width: 70vw;
	}
	.titleDiv {
		position: initial;
		justify-content: center;
		font-size: 2.3rem;
	}
}

@media screen and (max-width: 400px) {
	.parallaxDiv {
		font-size: 2.2rem;
	}
	.parallaxImg {
		margin-bottom: 2em;
	}
	.parallaxContent {
		font-size: 1.2rem;
		border-radius: 5px;
	}
	section {
		height: 90vh;
	}
}

@media screen and (max-height: 700px) {
	section {
		height: 110vh;
	}
}

@media screen and (max-width: 1300px) {
	.titleDiv {
		font-size: 4rem;
	}
}
