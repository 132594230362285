.registerContainer {
	border: solid 1px #0f0;
	border-radius: 5px;
	padding: 4rem 3.5rem 0rem;
	/* height: 70vh; */
	max-height: 85vh;
	/* margin-top: 3rem; */
	background: rgba(4, 4, 4, 0.9);
	width: 680px !important;
	overflow-y: scroll;
	scrollbar-width: none;
}

@supports (backdrop-filter: blur(10px)) {
	.registerContainer {
		background: rgba(4, 4, 4, 0.3) !important;
		backdrop-filter: blur(10px);
	}
}

/* https://bugzilla.mozilla.org/show_bug.cgi?id=748518 */
.registerContainer:after {
	content: ' ';
	height: 3.5rem;
	display: block;
}

.registerContainer h1 {
	/* display: inline-block; */
	position: relative;
}

.registerContainer::-webkit-scrollbar {
	display: none;
}

.informationBox {
	width: 100% !important;
	/* height: 90%; */
	margin-left: 3rem;
	padding: 2rem 1rem 0;
	transform: translateX(-3rem);
	zoom: 1.05;
	color: #f1f1f1 !important;
	scrollbar-width: none;
}

.informationBox * {
	zoom: 1.01;
}

.informationBox .hot-singles-in-your-area {
	display: flex;
	justify-content: center;
	align-items: center;
}

.informationBox .hot-singles-in-your-area > div {
	width: fit-content;
}

.registerForm {
	margin: 2rem 0.5rem;
	color: #f1f1f1 !important;
}

.registerForm > * {
	font-size: 1.1rem;
	padding-bottom: 1rem;
}

.registerForm label {
	font-size: 1.2rem !important;
	margin-bottom: 1rem !important;
	color: #f1f1f1 !important;
}

/* {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
} */

.sexyAnimation {
	position: absolute !important;
	top: -60%;
	right: 0%;
	/* transform: translate(-15px, -20px); */
	/* animation: light 10s infinite; */
	/* -webkit-filter: drop-shadow(0 0 2px #0f0);
  filter: drop-shadow(0 0 2px #0f0); */
}

.loader {
	border: 3px solid #f3f3f3; /* Light grey */
	border-top: 3px solid #17c917; /* Blue */
	border-radius: 50%;
	width: 30px;
	height: 30px;
	margin: auto;
	animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

@media only screen and (max-width: 789px) {
	.registerContainer {
		padding: 4rem 1rem 2.5rem;
	}

	.registerContainer h1 {
		font-size: 2rem !important;
	}

	.informationBox {
		padding: 4rem 0.5rem 2.5rem;
	}

	.sexyAnimation {
		transform: scale(0.9, 0.9);
		position: absolute;
		top: -50%;
		right: 2%;
		/* transform: translate(-15px, -25px); */
	}
}
