.footer {
	position: fixed;
	left: 0;
	bottom: 0;
	width: 100%;
	background-color: #000000aa;
	color: green;
	text-align: center;
	z-index: 103; /*admin side bar sucks ;(*/
}

.a {
	margin-left: 2px;
	text-decoration: none;
	color: green;
}

.a:hover {
	color: darkgreen;
	transition: 0.5s;
}

.h4 {
	letter-spacing: 1px;
	max-width: 300px;
}

@media only screen and (max-width: 862px) {
	.footerDash {
		position: fixed;
		left: 0;
		bottom: 0;
		width: 100%;
		background-color: rgb(20, 20, 20);
		color: green;
		text-align: center;
		margin-left: 4em;
	}
	.h3Dash {
		letter-spacing: 1px;
		width: calc(100% - 64px);
	}
}
