@import url(https://fonts.googleapis.com/css2?family=Chivo+Mono:wght@200&family=Inter:wght@800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Chivo+Mono:wght@200&family=Inter:wght@800&family=Roboto:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Space+Mono:wght@400;700&display=swap);
html,
body,
#root {
	width: 100%;
	height: 100%;
}

body {
	background-color: black;
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
		'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	height: 100vh;
}

#root {
	height: 100%;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@font-face {
	font-family: 'Neue Machina';
	src: url(/static/media/NeueMachina-Regular.6c408aee.otf);
	font-weight: 500;
}

@font-face {
	font-family: 'Neue Machina';
	src: url(/static/media/NeueMachina-Ultrabold.07d4eef9.otf);
	font-weight: bold;
}

@font-face {
	font-family: 'Neue Machina';
	src: url(/static/media/NeueMachina-Light.e1fb3b25.otf);
	font-weight: lighter;
}

.login_loginContainer__1btmz {
	border: solid 0.5px rgba(0, 255, 0, 0.69);
	border-radius: 5px;
	padding: 4rem 3.5rem 2.5rem;
	position: relative;
	/* height: 70vh; */
	/* min-height: 50vh; */
	max-height: 55vh;
	/* margin-top: 3rem; */
	background: rgba(4, 4, 4, 0.9);
	width: 400px !important;
	scrollbar-width: none;
	/* backdrop-filter: opacity(0.2); */
	overflow-y: scroll;
	color: white;
}

@supports ((-webkit-backdrop-filter: blur(10px)) or (backdrop-filter: blur(10px))) {
	.login_registerContainer__Yt0fE {
		background: rgba(4, 4, 4, 0.3) !important;
		-webkit-backdrop-filter: blur(10px);
		        backdrop-filter: blur(10px);
	}
}

.login_spinningImage__3N8aY {
	position: absolute;
	top: 35%;
	left: 50%;
	width: 120px;
	height: 120px;
	margin: -60px 0 0 -60px;
	-webkit-animation: login_spin__1ba63 01s ease-in-out infinite;
	animation: login_spin__1ba63 1s ease-in-out infinite;
}
@-webkit-keyframes login_spin__1ba63 {
	100% {
		-webkit-transform: rotateY(360deg);
	}
}
@keyframes login_spin__1ba63 {
	100% {
		-webkit-transform: rotateY(360deg);
		transform: rotateY(360deg);
	}
}

.login_contentBox__nD09M {
	position: relative;
	text-align: center;
}

.login_contentBox__nD09M p {
	text-align: center;
	margin-bottom: 10px;
}

.login_link__3Sygt {
	text-decoration: underline;
	color: #0f0;
	margin: 25px 5px;
	cursor: pointer;
}

.login_moreStuff__R1WX2 {
	position: absolute;
	bottom: 2rem;
	width: 80%;
	margin: auto;
}

.login_loginForm__2AOrN {
	margin: 2rem 0.5rem;
	height: -webkit-fit-content;
	height: -moz-fit-content;
	height: fit-content;
}

.login_loginForm__2AOrN > * {
	font-size: 1.2rem;
	padding-bottom: 1rem;
	color: #f1f1f1;
}

.login_loginForm__2AOrN label {
	font-size: 1.2rem !important;
	margin-bottom: 1rem !important;
	color: #f1f1f1 !important;
}

.login_LoginForm__YFxfM input::-webkit-outer-spin-button,
.login_LoginForm__YFxfM input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
	-moz-appearance: textfield;
}
.login_sexyAnimation__1KY8M {
	position: absolute !important;
	top: 5%;
	right: 5%;
	/* transform: translate(-15px, -18px); */
	/* animation: light 10s infinite; */
	/* -webkit-filter: drop-shadow(0 0 2px #0f0);
  filter: drop-shadow(0 0 2px #0f0); */
}

@media only screen and (max-width: 789px) {
	.login_loginContainer__1btmz {
		padding: 4rem 1.5rem 2.5rem;
		max-height: 85vh;
	}

	.login_loginContainer__1btmz h1 {
		font-size: 2rem !important;
		padding-bottom: 1rem !important;
	}

	.login_loginForm__2AOrN {
		margin: 0.5rem;
	}

	.login_sexyAnimation__1KY8M {
		-webkit-transform: scale(0.8, 0.8);
		        transform: scale(0.8, 0.8);
		position: absolute;
		top: -5%;
		right: -5%;
		/* transform: translate(-15px, -25px); */
	}
}

.style_img__2N2V8 {
	height: 100px;
	width: 100px;
	object-fit: contain;
	-webkit-animation-name: style_spinRotation__1doFd;
	        animation-name: style_spinRotation__1doFd;
	-webkit-animation-duration: 1s;
	        animation-duration: 1s;
	-webkit-animation-iteration-count: infinite;
	        animation-iteration-count: infinite;
}

.style_center__hA1bs {
	position: absolute;
	top: calc(50% - 50px);
	left: calc(50% - 50px);
}

@-webkit-keyframes style_spinRotation__1doFd {
	0% {
		-webkit-transform: rotateY(0deg);
		        transform: rotateY(0deg);
	}
	33% {
		-webkit-transform: rotateY(120deg);
		        transform: rotateY(120deg);
	}
	66% {
		-webkit-transform: rotateY(240deg);
		        transform: rotateY(240deg);
	}
	100% {
		-webkit-transform: rotateY(360deg);
		        transform: rotateY(360deg);
	}
}

@keyframes style_spinRotation__1doFd {
	0% {
		-webkit-transform: rotateY(0deg);
		        transform: rotateY(0deg);
	}
	33% {
		-webkit-transform: rotateY(120deg);
		        transform: rotateY(120deg);
	}
	66% {
		-webkit-transform: rotateY(240deg);
		        transform: rotateY(240deg);
	}
	100% {
		-webkit-transform: rotateY(360deg);
		        transform: rotateY(360deg);
	}
}

.Register_registerContainer__2EGOI {
	border: solid 1px #0f0;
	border-radius: 5px;
	padding: 4rem 3.5rem 0rem;
	/* height: 70vh; */
	max-height: 85vh;
	/* margin-top: 3rem; */
	background: rgba(4, 4, 4, 0.9);
	width: 680px !important;
	overflow-y: scroll;
	scrollbar-width: none;
}

@supports ((-webkit-backdrop-filter: blur(10px)) or (backdrop-filter: blur(10px))) {
	.Register_registerContainer__2EGOI {
		background: rgba(4, 4, 4, 0.3) !important;
		-webkit-backdrop-filter: blur(10px);
		        backdrop-filter: blur(10px);
	}
}

/* https://bugzilla.mozilla.org/show_bug.cgi?id=748518 */
.Register_registerContainer__2EGOI:after {
	content: ' ';
	height: 3.5rem;
	display: block;
}

.Register_registerContainer__2EGOI h1 {
	/* display: inline-block; */
	position: relative;
}

.Register_registerContainer__2EGOI::-webkit-scrollbar {
	display: none;
}

.Register_informationBox__1B683 {
	width: 100% !important;
	/* height: 90%; */
	margin-left: 3rem;
	padding: 2rem 1rem 0;
	-webkit-transform: translateX(-3rem);
	        transform: translateX(-3rem);
	zoom: 1.05;
	color: #f1f1f1 !important;
	scrollbar-width: none;
}

.Register_informationBox__1B683 * {
	zoom: 1.01;
}

.Register_informationBox__1B683 .Register_hot-singles-in-your-area__WnF2j {
	display: flex;
	justify-content: center;
	align-items: center;
}

.Register_informationBox__1B683 .Register_hot-singles-in-your-area__WnF2j > div {
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
}

.Register_registerForm__aqxTK {
	margin: 2rem 0.5rem;
	color: #f1f1f1 !important;
}

.Register_registerForm__aqxTK > * {
	font-size: 1.1rem;
	padding-bottom: 1rem;
}

.Register_registerForm__aqxTK label {
	font-size: 1.2rem !important;
	margin-bottom: 1rem !important;
	color: #f1f1f1 !important;
}

/* {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
} */

.Register_sexyAnimation__3FfWa {
	position: absolute !important;
	top: -60%;
	right: 0%;
	/* transform: translate(-15px, -20px); */
	/* animation: light 10s infinite; */
	/* -webkit-filter: drop-shadow(0 0 2px #0f0);
  filter: drop-shadow(0 0 2px #0f0); */
}

.Register_loader__2wRdN {
	border: 3px solid #f3f3f3; /* Light grey */
	border-top: 3px solid #17c917; /* Blue */
	border-radius: 50%;
	width: 30px;
	height: 30px;
	margin: auto;
	-webkit-animation: Register_spin__2OJ5p 1s ease-in-out infinite;
	        animation: Register_spin__2OJ5p 1s ease-in-out infinite;
}

@-webkit-keyframes Register_spin__2OJ5p {
	0% {
		-webkit-transform: rotate(0deg);
		        transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		        transform: rotate(360deg);
	}
}

@keyframes Register_spin__2OJ5p {
	0% {
		-webkit-transform: rotate(0deg);
		        transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		        transform: rotate(360deg);
	}
}

@media only screen and (max-width: 789px) {
	.Register_registerContainer__2EGOI {
		padding: 4rem 1rem 2.5rem;
	}

	.Register_registerContainer__2EGOI h1 {
		font-size: 2rem !important;
	}

	.Register_informationBox__1B683 {
		padding: 4rem 0.5rem 2.5rem;
	}

	.Register_sexyAnimation__3FfWa {
		-webkit-transform: scale(0.9, 0.9);
		        transform: scale(0.9, 0.9);
		position: absolute;
		top: -50%;
		right: 2%;
		/* transform: translate(-15px, -25px); */
	}
}

.sidebar_rootContainer__18y6a {
	margin: 0;
	max-width: 320px;
	display: flex;
	height: 100%;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	background-color: rgb(20, 20, 20);
	color: white;
	border-radius: 8px;
	overflow: auto;
}

.sidebar_rootContainer__18y6a::-webkit-scrollbar {
	width: 0;
}

.sidebar_userDetails__1w_kU {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.sidebar_domainIcons__14llc {
	margin: 8px !important;
}

.sidebar_domains__3axmG {
	margin-bottom: 8px;
}

.sidebar_identicon__2DanY {
	margin-top: 24px;
	border: solid #28a315 4px;
	height: 150px;
	width: 150px;
	background: black;
}

.sidebar_stats__2GHeb {
	margin-top: 24px;
	width: 100%;
}

.sidebar_statsGroup__1ENIW {
	width: 100% !important;
	margin: 0 !important;
	display: flex !important;
	align-items: center !important;
	justify-content: space-around !important;
}

.sidebar_statsGroup__1ENIW::after {
	content: none !important;
}

.sidebar_menu__hk0ZX {
	margin-top: auto;
	width: 100%;
}

.sidebar_menuItem__3cm4f {
	display: flex !important;
	padding: 16px !important;
}

.sidebar_menuIcon__2akHJ {
	margin: 0 !important;
	margin-right: 16px !important;
}

.customAccordion_accordion__3BmXJ {
	background-color: rgb(40, 40, 40);
	color: white;
	cursor: pointer;
	padding: 18px;
	width: 100%;
	text-align: left;
	border: none;
	outline: none;
	transition: 0.4s;
}

.customAccordion_active__a-vzf,
.customAccordion_accordion__3BmXJ:hover {
	background-color: #28a315;
}

.customAccordion_panel__1eX2f {
	padding: 0 18px;
	background-color: rgb(50, 50, 50);
	max-height: 0px;
	overflow: hidden;
	transition: max-height 0.2s ease-out;
}

.customAccordion_accordion__3BmXJ:after {
	content: '+';
	font-size: 13px;
	color: white;
	float: right;
	margin-left: 5px;
}

.customAccordion_active__a-vzf:after {
	color: white;
	content: '-';
}

.changeDomains_radio__3bncg {
	display: flex;
	margin: 2rem 2rem 0 2rem;
}

.dashboard_root__2qCnQ {
	height: 100%;
	padding: 2rem;
	background: black;
}

.dashboard_sidebar__3WM58 {
	height: 92vh;
	width: 300px;
	position: fixed;
}

.dashboard_miniSidebar__3uCom {
	display: none;
}

.dashboard_main__1wlwg {
	height: 100%;
	color: white;
	margin-left: 300px;
	padding: 0rem 1rem 0rem 3rem;
}

.dashboard_menuContainer__3GTyG {
	padding: 24px;
	background: rgb(20, 20, 20);
	height: 100%;
	border-radius: 8px;
	overflow: auto;
}

.dashboard_pyChallengeImage__DTEh9 > img {
	border-radius: 50%;
}

@media only screen and (max-width: 862px) {
	.dashboard_root__2qCnQ {
		padding: 0;
	}

	.dashboard_miniSidebar__3uCom {
		padding-top: 1rem;
		display: flex;
		height: 100vh;
		flex-direction: column;
		align-items: center;
		justify-content: flex-start;
		position: fixed;
		width: 4rem;
		background: #282828;
	}

	.dashboard_sidebar__3WM58 {
		-webkit-transform: translate(-400px);
		        transform: translate(-400px);
		height: 100vh;
		transition: -webkit-transform 400ms ease;
		transition: transform 400ms ease;
		transition: transform 400ms ease, -webkit-transform 400ms ease;
		z-index: 100000000;
	}

	.dashboard_main__1wlwg {
		margin-left: 4rem;
		margin-right: 0rem;
		padding: 0;
	}

	.dashboard_menuContainer__3GTyG {
		padding: 16px;
		background: rgb(20, 20, 20);
		height: 100%;
		border-radius: 8px;
		overflow: auto;
	}
}

.usercard_card__rYJbF {
	background: rgb(50, 50, 50);
	color: white;
	padding: 1.5rem;
	display: flex;
	justify-content: space-evenly;
	width: 100%;
	border-radius: 1rem;
}

.usercard_image__VckP2 {
	display: flex;
	flex-direction: column;
}

h3 {
	margin-bottom: 0.5rem !important;
}

.usercard_info__A1LGY {
	margin-left: 1.5rem;
	display: flex;
	flex-direction: column;
	align-items: left;
}

.usercard_header__1ZrOJ,
.usercard_extra__2lYJf,
.usercard_info__A1LGY {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.usercard_extra__2lYJf {
	margin-top: auto;
}

.usercard_identicon__1k4bu {
	border: solid #28a315 4px;
	max-width: 140px;
	height: 140px;
	background: black;
}

.usercard_interests__KZ0Bp {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	max-width: 200px;
	margin-bottom: 8px;
}

.usercard_taskProgress__1h-Rr {
	padding: 0.5rem;
	display: flex;
	justify-content: center;
}

@media only screen and (max-width: 530px) {
	.usercard_card__rYJbF {
		flex-direction: column;
		align-items: center;
	}

	.usercard_info__A1LGY {
		margin-left: 0;
		margin-top: 1rem;
	}

	.usercard_header__1ZrOJ {
		margin-bottom: 1.5rem;
	}
}

.SubmissionCard_card__1CMWb {
	display: flex;
	flex-direction: column;
	padding: 1.5rem 0;
}

.mentorSub_reviewHeader__1fGw4 {
	margin-bottom: 12px !important;
}



/* *:not(a) {
  color: #f1f1f1 !important;
} */

.App {
	/* text-align: center; */
	min-height: 100vh;
	position: relative;
	height: 100%;
	height: -webkit-fit-content;
	height: -moz-fit-content;
	height: fit-content;
	-webkit-backdrop-filter: blur(5px);
	        backdrop-filter: blur(5px);
}

canvas {
	display: block;
	height: 100%;
	width: 100%;
	position: fixed;
	top: 0;
	left: 0;
}

::-webkit-scrollbar-track {
	box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	background-color: transparent;
	visibility: hidden;
}

:hover::-webkit-scrollbar-track {
	visibility: visible;
}

::-webkit-scrollbar {
	width: 6px;
	background-color: transparent;
}

::-webkit-scrollbar-thumb {
	background-color: #28a745;
	visibility: hidden;
}

:hover::-webkit-scrollbar-thumb {
	visibility: visible;
}

a {
	color: #28a745;
}

.nav-pills .nav-link.active {
	background-color: #28a745;
}

@media only screen and (max-width: 500px) {
	.Toastify__toast {
		max-width: 300px;
		float: right;
	}
}

.style_footer__gbSax {
	position: fixed;
	left: 0;
	bottom: 0;
	width: 100%;
	background-color: #000000aa;
	color: green;
	text-align: center;
	z-index: 103; /*admin side bar sucks ;(*/
}

.style_a__2TR0l {
	margin-left: 2px;
	text-decoration: none;
	color: green;
}

.style_a__2TR0l:hover {
	color: darkgreen;
	transition: 0.5s;
}

.style_h4__1xkiV {
	letter-spacing: 1px;
	max-width: 300px;
}

@media only screen and (max-width: 862px) {
	.style_footerDash__19eRp {
		position: fixed;
		left: 0;
		bottom: 0;
		width: 100%;
		background-color: rgb(20, 20, 20);
		color: green;
		text-align: center;
		margin-left: 4em;
	}
	.style_h3Dash__1Bwzv {
		letter-spacing: 1px;
		width: calc(100% - 64px);
	}
}

.Profile_flexBox__c3Rcm {
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 100%;
	height: -webkit-fit-content;
	height: -moz-fit-content;
	height: fit-content;
}

.Profile_profileContainer__3lONb {
	background: #1c1c1c;
	padding: 4rem 2rem 5rem;
	position: relative;
	border-radius: 10px;
	box-shadow: 0 0 0 2px rgba(40, 167, 70, 0.65);
	transition: 0.4s;
	margin: 2.5rem;
}

.Profile_profileContainer__3lONb h1,
.Profile_profileSubContainer__2x7c7 h1 {
	margin-bottom: 2rem !important;
	color: #f1f1f1 !important;
	font-size: 2.5rem !important;
}

.Profile_profileSubContainer__2x7c7 {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
}

.Profile_profileSubContainer__2x7c7 > div {
	width: 50%;
}

.Profile_profileSubContainer__2x7c7 > div:nth-of-type(2) {
	zoom: 1.2;
}

.Profile_styledCards__2LTGo {
	background: transparent !important;
	color: #f1f1f1 !important;
	border: none !important;
	box-shadow: none !important;
}
.Profile_styledCards__2LTGo *:not(a) {
	background: transparent !important;
	color: #f1f1f1 !important;
	border: none !important;
}

.Profile_styledCards__2LTGo img {
	width: 180px !important;
	margin: auto;
}

.Profile_profileImage__136Mz {
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
	margin: auto;
}

.Profile_profileImage__136Mz img {
	cursor: pointer;
	transition: 0.4s;
	position: relative;
	border: solid 0rem #28a746 !important;
}

.Profile_profileImage__136Mz img:hover {
	border: solid 0.5rem #28a746 !important;
}

.Profile_profileImage__136Mz::before {
	content: '';
	display: block;
	width: 100%;
	background-color: #28a746;
	margin: auto;
	height: 0px;
	position: absolute;
	bottom: 0px;
	transition: all 0.4s;
	z-index: 100;
	color: rgba(255, 255, 255, 0);
}

.Profile_profileImage__136Mz:hover::before {
	content: attr(data-role);
	color: rgba(255, 255, 255, 1);
	text-transform: uppercase;
	font-weight: 600;
	height: 20px;
}

/*Edit Profile Page*/
.Profile_profileForm__1enkd {
	margin: 2rem 0.5rem;
	color: #f1f1f1 !important;
}
.Profile_profileForm__1enkd > * {
	font-size: 1.1rem;
	padding-bottom: 1rem;
}

.Profile_profileForm__1enkd label {
	font-size: 1.3rem !important;
	margin-bottom: 1rem !important;
	color: #f1f1f1 !important;
}

@media only screen and (max-width: 800px) {
	.Profile_profileContainer__3lONb {
		width: 90% !important;
	}

	.Profile_profileSubContainer__2x7c7 {
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	.Profile_profileSubContainer__2x7c7 > div {
		width: 100% !important;
		margin: 3rem 2rem;
	}
}

.admin_sidebar__3T1MA {
	width: 100px !important;
	top: 62px !important;
}

.admin_allocationConatiner__2RSdb {
	position: relative;
	top: 72px;
	left: 110px;
	height: calc(100% - 100px);
	width: calc(100% - 120px);
	background: black;
	border-radius: 8px;
	margin: none;
	/* overflow-y: scroll; */
}

.admin_a__50fjL {
	padding: 10px 8px !important;
}

.admin_h3__1ZGmz {
	padding-top: 40px;
	color: green;
	text-align: center;
}
.admin_green__31-b8 {
	color: green;
}
.admin_allocationTab__1nr7T {
	margin-top: 15px !important;
	padding: 0px 0px 5px 10px !important;
	height: calc(100% - 70px);
	overflow-y: scroll;
	overflow-x: hidden;
}

.admin_thead__19f35 {
	background: rgb(65, 64, 64) !important;
	/* padding: 10px 5px !important; */
}
.admin_active__2uBql {
	background: green !important;
}

@media only screen and (max-width: 700px) {
	.admin_mobileth__2KsRj {
		padding: 0px !important;
	}

	.admin_mobiletd__1pBJv {
		padding: 0px !important;
		background: rgb(29, 29, 29);
	}
}

.PageNotFound_cont__1-RHZ {
	background: radial-gradient(#000, #111);
	height: 100% !important;
	margin-left: 0 !important;
	margin-right: 0 !important;
	position: relative;
	overflow: hidden;
	display: flex;
	justify-content: center;
	align-items: center;
}

.PageNotFound_static__3SZJG {
	width: 100%;
	height: 100%;
	position: relative;
	margin: 0;
	padding: 0;
	top: -100px;
	opacity: 0.05;
	z-index: 230;
	-webkit-user-select: none;
	        user-select: none;
}

.PageNotFound_returnBtn__2LIZe {
	position: absolute;
	bottom: 60px;
	width: 200px;
	z-index: 250;
}

.PageNotFound_error__12bvz {
	text-align: center !important;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
		'Droid Sans', 'Helvetica Neue', sans-serif;
	font-size: 15rem;
	font-style: bold;
	text-align: center;
	color: white;
	width: auto;
	height: 3rem;
	line-height: 60px;
	margin: auto;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	-webkit-animation: PageNotFound_noise__koe4R 2s linear infinite;
	        animation: PageNotFound_noise__koe4R 2s linear infinite;
}

.PageNotFound_error__12bvz:after {
	content: '404';
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
		'Droid Sans', 'Helvetica Neue', sans-serif;
	font-size: 16rem;
	text-align: center;
	width: auto;
	height: 3rem;
	line-height: 60px;
	margin: auto;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 20px;
	right: 0;
	opacity: 0;
	color: blue;
	-webkit-animation: PageNotFound_noise-1__3PN_V 0.2s linear infinite;
	        animation: PageNotFound_noise-1__3PN_V 0.2s linear infinite;
}

.PageNotFound_error__12bvz:before {
	content: '404';
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
		'Droid Sans', 'Helvetica Neue', sans-serif;
	font-size: 16rem;
	text-align: center;
	width: auto;
	height: 3rem;
	line-height: 60px;
	margin: auto;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 20px;
	right: 0;
	opacity: 0;
	color: rgb(38, 255, 0);
	-webkit-animation: PageNotFound_noise-2__3NCXC 0.2s linear infinite;
	        animation: PageNotFound_noise-2__3NCXC 0.2s linear infinite;
}

.PageNotFound_info__PxdMX {
	text-align: center;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
		'Droid Sans', 'Helvetica Neue', sans-serif;
	font-style: italic;
	color: white;
	text-align: center;
	width: auto;
	height: 6rem;
	line-height: 60px;
	margin: auto;
	position: absolute;
	bottom: 80px;
	left: 0;
	right: 0;
	-webkit-animation: PageNotFound_noise-3__3GjVZ 1s linear infinite;
	        animation: PageNotFound_noise-3__3GjVZ 1s linear infinite;
}

@media screen and (max-width: 768px) {
	.PageNotFound_cont__1-RHZ {
		background: radial-gradient(#000, #111);
		height: 100% !important;
		width: 100% !important;
		margin-left: 0 !important;
		margin-right: 0 !important;
		position: relative;
		overflow: hidden;
	}

	.PageNotFound_error__12bvz {
		text-align: center !important;
		font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
			'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
		font-size: 10rem;
		font-style: bold;
		text-align: center;
		color: white;
		width: 5rem;
		height: 3rem;
		line-height: 60px;
		margin: auto;
		position: absolute;
		top: 0;
		bottom: 0;
		left: -15rem;
		right: 0;
		-webkit-animation: PageNotFound_noise__koe4R 2s linear infinite;
		        animation: PageNotFound_noise__koe4R 2s linear infinite;
	}

	.PageNotFound_error__12bvz:after {
		content: '404';
		font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
			'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
		font-size: 10rem;
		font-style: italic;
		text-align: center;
		width: 5rem;
		height: 3rem;
		line-height: 60px;
		margin: auto;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		opacity: 0;
		color: blue;
		-webkit-animation: PageNotFound_noise-1__3PN_V 0.2s linear infinite;
		        animation: PageNotFound_noise-1__3PN_V 0.2s linear infinite;
	}

	.PageNotFound_error__12bvz:before {
		content: '404';
		font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
			'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
		font-size: 10rem;
		font-style: italic;
		text-align: center;
		width: 5rem;
		height: 3rem;
		line-height: 60px;
		margin: auto;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		opacity: 0;
		color: rgb(38, 255, 0);
		-webkit-animation: PageNotFound_noise-2__3NCXC 0.2s linear infinite;
		        animation: PageNotFound_noise-2__3NCXC 0.2s linear infinite;
	}
}

@-webkit-keyframes PageNotFound_noise-1__3PN_V {
	0%,
	20%,
	40%,
	60%,
	70%,
	90% {
		opacity: 0;
	}
	10% {
		opacity: 0.1;
	}
	50% {
		opacity: 0.5;
		left: -6px;
	}
	80% {
		opacity: 0.3;
	}
	100% {
		opacity: 0.6;
		left: 2px;
	}
}

@keyframes PageNotFound_noise-1__3PN_V {
	0%,
	20%,
	40%,
	60%,
	70%,
	90% {
		opacity: 0;
	}
	10% {
		opacity: 0.1;
	}
	50% {
		opacity: 0.5;
		left: -6px;
	}
	80% {
		opacity: 0.3;
	}
	100% {
		opacity: 0.6;
		left: 2px;
	}
}

@-webkit-keyframes PageNotFound_noise-2__3NCXC {
	0%,
	20%,
	40%,
	60%,
	70%,
	90% {
		opacity: 0;
	}
	10% {
		opacity: 0.1;
	}
	50% {
		opacity: 0.5;
		left: 6px;
		top: 10px;
	}
	80% {
		opacity: 0.3;
	}
	100% {
		opacity: 0.6;
		left: -2px;
		top: -5px;
	}
}

@keyframes PageNotFound_noise-2__3NCXC {
	0%,
	20%,
	40%,
	60%,
	70%,
	90% {
		opacity: 0;
	}
	10% {
		opacity: 0.1;
	}
	50% {
		opacity: 0.5;
		left: 6px;
		top: 10px;
	}
	80% {
		opacity: 0.3;
	}
	100% {
		opacity: 0.6;
		left: -2px;
		top: -5px;
	}
}

@-webkit-keyframes PageNotFound_noise__koe4R {
	0%,
	3%,
	5%,
	42%,
	44%,
	100% {
		opacity: 1;
		-webkit-transform: scaleY(1);
		        transform: scaleY(1);
	}
	4.3% {
		opacity: 1;
		-webkit-transform: scaleY(1.7);
		        transform: scaleY(1.7);
	}
	43% {
		opacity: 1;
		-webkit-transform: scaleX(1.5);
		        transform: scaleX(1.5);
	}
}

@keyframes PageNotFound_noise__koe4R {
	0%,
	3%,
	5%,
	42%,
	44%,
	100% {
		opacity: 1;
		-webkit-transform: scaleY(1);
		        transform: scaleY(1);
	}
	4.3% {
		opacity: 1;
		-webkit-transform: scaleY(1.7);
		        transform: scaleY(1.7);
	}
	43% {
		opacity: 1;
		-webkit-transform: scaleX(1.5);
		        transform: scaleX(1.5);
	}
}

@-webkit-keyframes PageNotFound_noise-3__3GjVZ {
	0%,
	3%,
	5%,
	42%,
	44%,
	100% {
		opacity: 1;
		-webkit-transform: scaleY(1);
		        transform: scaleY(1);
	}
	4.3% {
		opacity: 1;
		-webkit-transform: scaleY(4);
		        transform: scaleY(4);
	}
	43% {
		opacity: 1;
		-webkit-transform: scaleX(10) rotate(60deg);
		        transform: scaleX(10) rotate(60deg);
	}
}

@keyframes PageNotFound_noise-3__3GjVZ {
	0%,
	3%,
	5%,
	42%,
	44%,
	100% {
		opacity: 1;
		-webkit-transform: scaleY(1);
		        transform: scaleY(1);
	}
	4.3% {
		opacity: 1;
		-webkit-transform: scaleY(4);
		        transform: scaleY(4);
	}
	43% {
		opacity: 1;
		-webkit-transform: scaleX(10) rotate(60deg);
		        transform: scaleX(10) rotate(60deg);
	}
}

.LandingPage_landingpage__TtnWy {
	height: 100vh;
	display: flex;
	flex-direction: column;
	scroll-snap-align: center;
}

.LandingPage_page1__1UjHS {
	flex: 1 1 auto;
	display: flex;
	align-items: center;
	justify-content: center;
}

.LandingPage_buttonNav__2jQL3 {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	padding: 1.5rem 3rem;
	margin-bottom: 0rem;
	height: -webkit-fit-content;
	height: -moz-fit-content;
	height: fit-content;
}

.LandingPage_navButton__2P5Lv {
	font-size: 1.3rem !important;
	margin-left: 1rem !important;
	z-index: 10000;
}

.LandingPage_landinglogoAndText__3Ga6V {
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: 1fr;
	height: 100%;
	width: 100%;
}

.LandingPage_logo__1cS9z {
	grid-row: 1 / -1;
	grid-column: 1 / -1;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 80vh;
	width: 100vw;
	-webkit-animation: LandingPage_logotransform__3_SGL 5s infinite;
	        animation: LandingPage_logotransform__3_SGL 5s infinite;
}

.LandingPage_logo__1cS9z > img {
	width: 80%;
	height: 80%;
}

.LandingPage_landingtext__1e-NR {
	grid-row: 1/-1;
	grid-column: 1/-1;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-evenly;
	color: #ffffff;
	font-size: 6em;
	z-index: 4;
	letter-spacing: 0.2rem;
	font-family: 'Neue Machina';
	font-weight: bold;
}

@media (max-width: 1000px) {
	.LandingPage_landingtext__1e-NR {
		font-size: 5em;
	}
}

@media (min-width: 360px) and (max-width: 500px) {
	.LandingPage_landingtext__1e-NR {
		position: relative;
		bottom: 3%;
	}
}

@media (max-width: 500px) {
	.LandingPage_logo__1cS9z > img {
		width: 60%;
		height: 60%;
	}

	.LandingPage_landingtext__1e-NR {
		font-size: 2em !important;
	}
}

@media (max-width: 800px) {
	.LandingPage_landingtext__1e-NR {
		font-size: 3em;
	}
	.LandingPage_landingpage__TtnWy {
		scroll-snap-align: none;
	}
}

.content__slide,
.content__text-wrap {
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	align-items: center;
}

.content__text-inner--stroke {
	-webkit-text-stroke: 2px white;
	text-stroke: 2px white;
	-webkit-text-fill-color: transparent;
	text-fill-color: transparent;
	color: transparent;
}

.content__text {
	opacity: 0;
}

.imp_text {
	opacity: 1 !important;
	text-shadow: 5px 5px 1rem #000000d4 !important;
}

.WhyDeltaPage_whydeltapage__1NH4Q {
	height: 100vh;
	display: flex;
	width: 100vw;
	scroll-snap-align: center;
	position: relative;
	flex-direction: column;
}

.WhyDeltaPage_deltacarousel__2JSfH {
	flex-basis: 50%;
	height: 100%;
}

.WhyDeltaPage_whydeltacontainer__3kvVH {
	display: flex;
	height: 70%;
	width: 100%;
	align-items: center;
	justify-content: space-evenly;
}

.WhyDeltaPage_whydeltacontent__2jE0S {
	flex-basis: 50%;
	height: 80%;
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	align-items: center;
	position: relative;
}

.WhyDeltaPage_whydeltaheading__3C8KX {
	width: 95%;
	height: 20%;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	color: white;
	font-size: 4rem;
	font-family: 'Neue Machina';
	font-weight: bold;
}

.WhyDeltaPage_content__22Fvj {
	width: 40%;
	height: -webkit-fit-content;
	height: -moz-fit-content;
	height: fit-content;
	line-height: 2rem;
	padding: 2rem 2rem;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #00000099;
	-webkit-backdrop-filter: blur(5px);
	        backdrop-filter: blur(5px);
	border-radius: 10px;
	color: white;
	font-family: 'Lato', 'Helvetica Neue', 'Arial', 'Helvetica', 'sans-serif';
	font-size: 1.3rem;
	font-weight: 200;
}

.WhyDeltaPage_deltacarousel__2JSfH {
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
}

.WhyDeltaPage_deltacarousel__2JSfH > img {
	height: auto;
	width: 80%;
	border-radius: 10px;
}

@media (max-width: 500px) {
	.WhyDeltaPage_whydeltacontainer__3kvVH {
		flex-direction: column-reverse;
	}
	.WhyDeltaPage_whydeltapage__1NH4Q {
		scroll-snap-align: none;
	}
	.WhyDeltaPage_content__22Fvj {
		width: 90%;
		font-size: 1rem;
	}

	.WhyDeltaPage_whydeltaheading__3C8KX {
		font-size: 2rem;
		justify-content: center;
		margin-bottom: 1rem;
	}

	.WhyDeltaPage_content__22Fvj {
		margin-bottom: 1rem;
	}
}

.DomainsPage_domaincontainer__1DY7c {
	padding: 3em;
	width: 25vw;
	height: 70vh;
	display: flex;
	flex-direction: column;
	/* border: #a0ff28 2px solid; */
	border-radius: 10px;
	-webkit-backdrop-filter: blur(10px);
	        backdrop-filter: blur(10px);
	background-color: #00000099;
	justify-content: space-between;
	transition: box-shadow 0.3s;
}

.DomainsPage_domaincontainer__1DY7c:hover {
	box-shadow: 10px 10px #211e1e1a;
}

.DomainsPage_domainimage__17uPt {
	display: flex;
	justify-content: center;
	align-items: center;
	padding-top: 3em;
	position: relative;
}

.DomainsPage_domainheading__259f- {
	width: 95%;
	height: 10%;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	color: white;
	font-size: 4rem;
	font-family: 'Neue Machina';
	font-weight: bold;
}

.DomainsPage_domainimage__17uPt > img {
	border-radius: 10px;
	max-width: 60% !important;
}

.DomainsPage_domaintitle__2o5C8 {
	text-align: center;
	font-family: 'Roboto';
	padding: 0.5em;
	font-size: 3em;
	color: #a0ff28;
	text-underline-offset: 10px;
	-webkit-text-decoration: underline #1f3d0c 3px;
	        text-decoration: underline #1f3d0c 3px;
}

.DomainsPage_domaincontent__25LwQ {
	padding: 0.3em;
	font-family: 'Roboto';
	font-weight: 100;
	color: #d7dae5;
	line-height: 1.2em;
	font-size: 1.7em;
	text-align: center;
	overflow: auto;
}

.DomainsPage_domains__1_ZLw {
	display: flex;
	width: 100%;
	height: 80%;
	align-items: center;
	justify-content: space-evenly;
}

.DomainsPage_domainspage__3cWNI {
	height: 100vh;
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	scroll-snap-align: center;
	flex-direction: column;
	position: relative;
}

@media (max-width: 1500px) {
	.DomainsPage_domaincontent__25LwQ {
		font-size: 1.2em;
	}
	.DomainsPage_domainspage__3cWNI {
		padding: 0 2em;
		display: flex;
		justify-content: space-evenly;
		align-items: center;
	}
	.DomainsPage_domaintitle__2o5C8 {
		font-size: 2.5em;
		margin-bottom: 1em;
	}
}

@media (max-width: 1500px) and (min-width: 1101px) {
	.DomainsPage_domaincontainer__1DY7c {
		position: relative;
		padding: 3em;
		width: 23rem;
		height: 35rem;
	}

	.DomainsPage_domainimage__17uPt > img {
		margin-top: -2rem;
		height: 8rem;
		width: auto;
	}
}

@media (max-width: 1100px) {
	.DomainsPage_domaincontent__25LwQ {
		font-size: 1.2em;
	}
}

@media (max-width: 1100px) and (min-width: 601px) {
	.DomainsPage_domainspage__3cWNI {
		flex-direction: column;
		height: auto;
		padding: 0 4em;
		grid-gap: 5rem;
		gap: 5rem;
		margin-bottom: 6rem;
	}

	.DomainsPage_domains__1_ZLw {
		grid-gap: 3vw;
		gap: 3vw;
	}

	.DomainsPage_domaintitle__2o5C8 {
		font-size: 2em;
	}

	.DomainsPage_domainimage__17uPt > img {
		margin-top: -4rem;
		height: 10rem;
		width: auto;
	}

	.DomainsPage_domaincontainer__1DY7c {
		position: relative;
		padding: 3em;
		width: 30rem;
		height: 35rem;
		display: flex;
		flex-direction: column;
		background-color: #000003;
		justify-content: space-between;
		text-align: center;
	}
}

@media (max-width: 600px) {
	.DomainsPage_domainheading__259f- {
		font-size: 2rem;
		justify-content: center;
	}

	.DomainsPage_domains__1_ZLw {
		flex-direction: column;
		grid-gap: 10vh;
		gap: 10vh;
	}

	.DomainsPage_domainspage__3cWNI {
		height: auto;
		padding: 0 3em;
		grid-gap: 5rem;
		gap: 5rem;
		margin-bottom: 6rem;
		flex-direction: column;
		scroll-snap-align: none;
	}

	.DomainsPage_domainimage__17uPt > img {
		margin-top: -4rem;
		height: 10rem;
		width: auto;
	}

	.DomainsPage_domaincontainer__1DY7c {
		padding: 1.3em;
		width: 80vw;
		height: 35rem;
		/* max-height: 35rem; */
		display: flex;
		flex-direction: column;
		justify-content: space-evenly;
		text-align: center;
	}

	.DomainsPage_domaincontent__25LwQ {
		font-size: 1.1em;
	}
}

html {
	scroll-snap-type: y mandatory;
	scroll-behavior: smooth;
}

section {
	height: 100vh;
	display: flex !important;
	justify-content: space-between;
	align-items: center;
	scroll-snap-align: center;
	-webkit-perspective: 500px;
	        perspective: 500px;
	margin: 0 3.5vw;
}

.ProjectPage_titleDiv__1fhwU {
	font-size: 5rem;
	color: #eaeaea;
	-webkit-filter: drop-shadow(4px 8px 1rem #000000);
	        filter: drop-shadow(4px 8px 1rem #000000);
	font-family: 'Neue Machina';
	font-weight: bold;
	position: absolute;
	display: flex;
	width: 92vw;
	justify-content: flex-end;
	top: 5vh;
	align-items: center;
}

.ProjectPage_parallaxImgContainer__1STWC {
	position: absolute;
	left: 12vw;
	z-index: -1;
}

.ProjectPage_parallaxImg__8N05n {
	max-height: 60vh;
	height: 100%;
	width: auto;
	max-width: 45vw;
	border-radius: 15px;
}
.ProjectPage_parallaxDiv__1sqRb {
	font-size: 5rem;
	line-height: 5.2rem;
	max-width: 35vw;
	width: -webkit-max-content;
	width: max-content;
	color: #eaeaea;
	-webkit-filter: drop-shadow(4px 8px 1rem #000000);
	        filter: drop-shadow(4px 8px 1rem #000000);
	font-family: 'Neue Machina';
	font-weight: bold;
}
.ProjectPage_parallaxContent__zY2MP {
	justify-self: flex-end;
	font-size: 1.5rem;
	color: #eaeaea;
	margin-right: 2rem;
	max-width: 40vw;
	line-height: 2.25rem;
	background-color: #00000099;
	-webkit-backdrop-filter: blur(5px);
	        backdrop-filter: blur(5px);
	padding: 2rem;
	border-radius: 10px;
}

@media screen and (max-width: 500px) {
	section {
		flex-direction: column;
		justify-content: space-between;
		height: 80vh;
		scroll-snap-align: none;
	}
	.ProjectPage_parallaxDiv__1sqRb {
		max-width: inherit;
		font-size: 2.6rem;
	}
	.ProjectPage_parallaxImgContainer__1STWC {
		position: inherit;
		top: 40vh;
	}
	.ProjectPage_parallaxContent__zY2MP {
		max-width: 90vw;
		margin-right: 0;
	}
	.ProjectPage_parallaxImg__8N05n {
		max-width: 70vw;
	}
	.ProjectPage_titleDiv__1fhwU {
		position: initial;
		justify-content: center;
		font-size: 2.3rem;
	}
}

@media screen and (max-width: 400px) {
	.ProjectPage_parallaxDiv__1sqRb {
		font-size: 2.2rem;
	}
	.ProjectPage_parallaxImg__8N05n {
		margin-bottom: 2em;
	}
	.ProjectPage_parallaxContent__zY2MP {
		font-size: 1.2rem;
		border-radius: 5px;
	}
	section {
		height: 90vh;
	}
}

@media screen and (max-height: 700px) {
	section {
		height: 110vh;
	}
}

@media screen and (max-width: 1300px) {
	.ProjectPage_titleDiv__1fhwU {
		font-size: 4rem;
	}
}

