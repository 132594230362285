.content__slide,
.content__text-wrap {
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	align-items: center;
}

.content__text-inner--stroke {
	-webkit-text-stroke: 2px white;
	text-stroke: 2px white;
	-webkit-text-fill-color: transparent;
	text-fill-color: transparent;
	color: transparent;
}

.content__text {
	opacity: 0;
}

.imp_text {
	opacity: 1 !important;
	text-shadow: 5px 5px 1rem #000000d4 !important;
}
