@import url('https://fonts.googleapis.com/css2?family=Space+Mono:wght@400;700&display=swap');
/* *:not(a) {
  color: #f1f1f1 !important;
} */

.App {
	/* text-align: center; */
	min-height: 100vh;
	position: relative;
	height: 100%;
	height: fit-content;
	backdrop-filter: blur(5px);
}

canvas {
	display: block;
	height: 100%;
	width: 100%;
	position: fixed;
	top: 0;
	left: 0;
}

::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	background-color: transparent;
	visibility: hidden;
}

:hover::-webkit-scrollbar-track {
	visibility: visible;
}

::-webkit-scrollbar {
	width: 6px;
	background-color: transparent;
}

::-webkit-scrollbar-thumb {
	background-color: #28a745;
	visibility: hidden;
}

:hover::-webkit-scrollbar-thumb {
	visibility: visible;
}

a {
	color: #28a745;
}

.nav-pills .nav-link.active {
	background-color: #28a745;
}

@media only screen and (max-width: 500px) {
	.Toastify__toast {
		max-width: 300px;
		float: right;
	}
}
